#mainDiv {
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;

  position: relative;
}
#navimg {
  width: 83px;
  height: 55px;
  padding-left: 5%;
}
.BoxStyle {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  border: none;
  height: 80%;
  background-color: white;

  box-shadow: 24;
  /* p: 4, */
  border-radius: 15px;
  overflow: scroll;
}
.BoxStyle::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.BoxStyle {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#createLink {
  display: flex;
  float: right;

  width: 400px;
  justify-content: right;
}
#linkbtn {
  background-color: #73a0ec;
  text-transform: none;
  width: 200px;
  font-weight: bold;
}
#linkInput {
  padding-left: 3%;
  width: 400px;
  background-color: #f4f4f4;
  border: none;
}
#linkCopy {
  width: 70px;
  height: 50px;
  background-color: #73a0ec;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  align-items: center;
}
#secondmainDiv {
  width: 90%;

  height: 100%;
  display: flex;

  padding-top: 2%;
}

#navbarr {
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.navp {
  font-family: "Sora";
  font-style: normal;
  
  padding-bottom: 0px;
  margin-bottom: 0px;
  
  font-weight: 600;
  height: fit-content;
  font-size: 40px;
  line-height: 50px;
}
#classnav {
  width: "83px";
  height: "55px";
}

#cross {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  #secondmainDiv {
    width: 100%;

    height: 100%;
    display: flex;

    align-items: center;
    flex-direction: column;

    padding-top: 10%;
  }
  #navbarr {
    width: 95%;

    height: 100px;
    position: relative;
    display: flex;

    align-items: center;
  }

  .navp {
    font-family: "Sora";
    font-style: normal;
    padding-bottom: 0px;

    text-align: center;
    padding: 0px;
    margin: 0px;
    margin-bottom: 0px;
  
    font-weight: 600;
    height: fit-content;
    

    font-size: 10px;
  }
  #navimg {
    width: 83px;
    height: 55px;

    padding-left: 0px;
  }
  #cross {
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding-left: 40px;
  }

  .BoxStyle {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    align-items: center;
    border: none;
    height: 80%;
    background-color: white;

    box-shadow: 24;
    /* p: 4, */
    border-radius: 15px;
    overflow: scroll;
  }
}


.custom-datepicker {
        width: 100% !important; /* Default to full width */
        height: 44px !important ;
      }
      @media (min-width: 600px) {
        .custom-datepicker {
          width: 300px !important;
          height: 44px;
          /* Adjust width on larger screens */
        }
      }